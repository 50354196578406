<template>
  <FormLabel v-if="data && (value !== undefined || rule)">
    <v-text-field
      :id="dataKey"
      v-if="rule"
      class="custom-input"
      :type="type || 'text'"
      density="compact"
      :disabled="disabled"
      :value="value"
      variant="outlined"
      :suffix="suffix"
      :placeholder="placeholder"

      :error-messages="getErrors()"
      @input="validation && validation.$touch(); $emit('input', $event)"
      @blur="validation && validation.$touch()"
      @update:model-value="$emit('updateData', $event)"
    >
      <template #append-inner>
        <slot name="appendInnerIcon" />
      </template>
    </v-text-field>
    <div v-else>
      <div v-if="value">
        <div v-if="withTooltip">
          <v-tooltip :text="$translate(`${translationName}.${dataKey}.tooltip.${value}`)">
            <template #activator="{ props }">
              <v-chip
                v-if="isChips"
                v-bind="props"
                :variant="$config.public.chips_variant || 'tonal'"
              >
                {{ value }} {{ suffix }}
              </v-chip>
              <span
                v-else
                v-bind="props"
              >{{ value }} {{ suffix }}</span>
            </template>
          </v-tooltip>
        </div>
        <v-chip v-if="isChips" :variant="$config.public.chips_variant || 'tonal'">
          {{ value }} {{ suffix }}
        </v-chip>
        <span v-else>{{ value }} {{ suffix }}</span>
        <slot name="appendContent" />
      </div>
      <div v-else>
        <small class="font-italic text-disabled">-</small>
      </div>
    </div>
  </FormLabel>
</template>

<script lang="ts">
import formFieldsInit from '../../utils/formFieldsInit.vue';
import FormLabel from "./label.vue";

export default {
    name : "InputField",
    components: {FormLabel},
    extends : formFieldsInit,
    emits: ['input', 'updateData'],
    mounted () {
        if (this.type === 'date') {
            // 2021-11-10T00:00:00+01:00 to 2023-10-28
            let formattedValue = this.value

            if (formattedValue) {
                formattedValue = formattedValue.substring(0, 10);
                this.$emit('updateData', formattedValue)
            }
        }
    }
}
</script>
